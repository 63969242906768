import { login } from '@/service'
import { IAccount } from '@/service/login/type'
import { defineStore } from 'pinia'
import cache from '@/utils/cache'
import router from '@/router'
import { ElMessage } from 'element-plus'
import { mapMenuPowerIds } from '@/utils/map-menus'

const useLoginStore = defineStore('login', {
  state: () => ({
    token: '',
    userInfo: {}
  }),
  actions: {
    async fetchLogin(data: IAccount) {
      const res = await login(data)
      const { token, name, phone, role_info } = res.data
      const menuList = mapMenuPowerIds(role_info.power_ids)
      cache.setCache('token', token)
      cache.setCache('name', name)
      cache.setCache('phone', phone)
      cache.setCache('powerIds', role_info.power_ids)
      cache.setCache('menuList', menuList)
      ElMessage.success('登录成功')
      router.push('/main')
    }
  }
})

export default useLoginStore
